import * as React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import {StaticImage} from "gatsby-plugin-image"
import {SectionHero, Container, SectionImageBkg} from "../components/Section"
import { Nodata,  NodataContainer, NodataDesc, NodataMedia } from "../components/Nodata"

const SectionHeros = styled(SectionHero)`
	position:relative;
    height: 100%;
    background: #1383c1;
    background: linear-gradient(to bottom,  #1383c1 0%, #074a6f 100%);
`
const SectionImageBkgs = styled(SectionImageBkg)`	
`

const HeroCaption = styled.div`	
    position:relative;
    z-index:1;
    width: 100%;
    padding:60px 0;
    @media (min-width:768px){
        padding:70px 0;
    }
    @media (min-width:992px){
        padding:80px 0;
    }
    @media (min-width:1200px){
        padding:90px 0;
    }
    @media (min-width:1600px){
        padding:120px 0;
    }
	
`
const ThankYouReview = ({ location }) => {
    return(    
        <Layout location={location}>
            <SectionHeros className="section-hero">
                <SectionImageBkgs opacity="0.1"><StaticImage src="../images/blue-pattern.jpg" alt="blue-pattern" /></SectionImageBkgs>
                <HeroCaption>
                    <Container>
                        <Nodata>
                            <NodataContainer maxWidth="670px">
                                <NodataMedia><StaticImage src="../images/thank-you-review.png" alt="thank-you" /></NodataMedia>
                                <NodataDesc color="#fff"><p>Thank you for submitting your information. Our team of experts will get in touch with you shortly.</p></NodataDesc>
                            </NodataContainer>
                        </Nodata>
                    </Container>
                </HeroCaption>
            </SectionHeros>
        </Layout>
    )
}
export default ThankYouReview